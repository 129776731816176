import axios from '@axios'

export default {
  async getERV(dosID) {
    try {
      const r = await axios.get('erv/getERV', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getERVTrans(dosID) {
    try {
      const r = await axios.get('erv/getERVTrans', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async putERVEingabe(data) { // To be implemented...
    try {
      const d = JSON.parse(JSON.stringify(data))
      const r = await axios.get('erv/getERVEingabeIns', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

}
