<template>
  <div v-if="(!e || !et)">Loading Please wait...
  </div>
  <div v-else>
    <b-form>

      <b-row>

        <b-col cols="12" md="3">
          <b-form-group
            label="Verfahren"
            label-for="verfahren"
          >
            <b-form-input :disabled="true"
              v-model="e.ArtBez"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group
            label="Zustelladresse"
            label-for="zustelladresse"
          >
            <b-form-input :disabled="true"
              v-model="e.Zustelladresse"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="2">
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="e.Status"
              :options="status"
              :reduce="val => val.value"
              :clearable="false"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <div class="mt-2 text-right">
            <b-button
              variant="primary"
              class="align-right"
              :disabled="e.Status !== 'ze'"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="addEingabe"
            >
              <feather-icon
                  icon="PlusCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">Weitere Eingabe</span>
            </b-button>
          </div>
        </b-col>

        <b-col md="2">
          <div class="mt-2 text-right">
            <b-button
              variant="primary"
              class="align-right"
              :disabled="e.BegehrenID === null"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="download('b')"
            >
              <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                <span class="align-middle">Zustellungen</span>
            </b-button>
          </div>
        </b-col>

      </b-row>

      <b-row>

        <b-col cols="12" md="3">
          <b-form-group
            label="Aktendeckel"
            label-for="aktendeckel"
          >
            <b-form-input :disabled="true"
              v-model="e.Aktendeckel"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <div class="mt-2 text-right">
            <b-button
              variant="primary"
              class="align-right"
              :disabled="e.BegehrenID === null"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="download('b')"
            >
              <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{begehren}}</span>
            </b-button>
          </div>
        </b-col>

        <b-col md="2">
          <div class="mt-2 text-right">
            <b-button
              variant="primary"
              class="align-right"
              :disabled="e.IsVereinbarung === 0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="download('k')"
            >
            <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{vereinbarung}}</span>
            </b-button>
          </div>
        </b-col>

        <b-col md="2">
          <div class="mt-2 text-right">
            <b-button
              variant="primary"
              class="align-right"
              :disabled="e.FamilienausweisID === null"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="download('f')"
            >
            <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Familienausweis</span>
            </b-button>
          </div>
        </b-col>

        <b-col md="2">
            <div class="mt-2 text-right">
              <b-button
                variant="primary"
                class="align-right"
                :disabled="e.IsVollmacht === 0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="download('v')"
              >
              <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Vollmacht</span>
              </b-button>
            </div>
        </b-col>

        </b-row>

        <b-row>
          <b-table
            ref="tblET"
            selectable
            select-mode="single"
            striped
            hover
            responsive
            class="position-relative mt-1"
            :items="et"
            primary-key="ERVTransID"
            selected-variant="primary"
            :fields="fieldsERVTrans"
            @row-selected="showERVTrans"
          >
            <template #row-details="row">

              <b-row v-show="row.item.Art === 'e' && row.item.IstErstEingabe">
                <b-col cols="12" md="12">
                  <blockquote>
                    <b>Information</b><br>
                    Zum Einreichen der (Erst-)Eingabe müssen das Begehren, die Vereinbarung, bzw. die Konvention sowie die Vollmacht erstellt worden sein. Zudem müssen die erforderlichen Belege wie z. B. der Familienausweis bereitsgestellt werden um Nachfragen und damit Verzögerungen im Verfahren zu vermeiden.
                </blockquote>
                </b-col>
              </b-row>

              <b-row v-show="row.item.Art === 'e' && row.item.IstErstEingabe">
                <b-col cols="12" md="3">
                  <b-form-group
                    label="Verfahren"
                    label-for="verfahren"
                  >
                    <b-form-input
                      v-model="e.ArtBez"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                  <b-form-group
                    label="Zustelladresse"
                    label-for="za"
                  >
                    <b-form-input
                      v-model="e.Zustelladresse"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                  <b-form-group
                    label="Status"
                    label-for="status"
                  >
                    <v-select
                      v-model="e.Status"
                      :options="status"
                      :reduce="val => val.value"
                      :clearable="false"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <div class="mt-2 text-right">
                    <b-button
                      variant="primary"
                      class="align-right"
                      :disabled="e.Status !== 'ev' || (e.BegehrenID === null || e.IsVereinbarung === null || e.BegehrenID === null || e.FamilienausweisID === null || e.Zustelladresse === null)"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="einreichen"
                    >
                      Einreichen
                    </b-button>
                  </div>
                </b-col>

              </b-row>

              <b-row v-show="row.item.Art === 'e' && row.item.IstErstEingabe">

                <b-table
                  ref="tblETB"
                  selectable
                  select-mode="single"
                  :current-page="currPage"
                  :per-page="8"
                  striped
                  hover
                  responsive
                  class="position-relative mt-1"
                  :items="be"
                  primary-key="DosBelegID"
                  selected-variant="primary"
                  :fields="fieldsBelege"
                  @row-selected="showBeleg"
                >
                  <!-- <template slot="Anzeigen">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      variant="outline-primary"
                      class="btn-icon mt-2"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </template> -->

                </b-table>

                <div>
                  <b-pagination
                    v-model="currPage"
                    :total-rows="be.length"
                    :per-page="8"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>

              </b-row>

              <b-row v-show="row.item.Art === 'z'">
                <b-col cols="12" md="3">
                  <b-form-group
                    label="Verfahren"
                    label-for="verfahren"
                  >
                    <b-form-input :disabled="true"
                      v-model="row.item.Bez"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                  <b-form-group
                    label="Aktendeckel"
                    label-for="deckel"
                  >
                    <b-form-input :disabled="true"
                      v-model="e.Aktendeckel"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                  <b-form-group
                    label="Frist"
                    label-for="frist"
                  >
                    <b-form-input :disabled="true"
                      v-model="row.item.FristTbl"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <div class="mt-2 text-right">
                    <b-button
                      variant="primary"
                      class="align-right"
                      :disabled="row.item.KalID !== null"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="createTermin(row.item)"
                    >
                      Termin erstellen
                    </b-button>
                  </div>
                </b-col>

                <b-col md="2">
                  <div class="mt-2 text-right">
                    <b-button
                      variant="primary"
                      class="align-right"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="einsicht"
                    >
                      Einsicht nehmen
                    </b-button>
                  </div>
                </b-col>

              </b-row>

              <b-row v-show="row.item.Art === 'e' && !row.item.IstErstEingabe">
                <b-col cols="12" md="3">
                  <b-form-group
                    label="Zusatzeingabe"
                    label-for="zeingabe"
                  >
                    <b-form-input
                      v-model="row.item.Bezeichnung"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <div class="mt-2 text-right">
                    <b-button
                      variant="primary"
                      class="align-right"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="einreichen"
                    >
                      Einreichen
                    </b-button>
                  </div>
                </b-col>

              </b-row>

            </template>

          </b-table>
        </b-row>
    </b-form>

    <div v-show="false">
      <pdf-viewer
        ref="pv"
        :pdf-in="pdfIn"
        :file-name="dateiName"
      />
    </div>

  </div>
</template>

<script>
// import Vue from 'vue'

import {
  BForm, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import erv from '@/service/erv'
import dos from '@/service/dossier'
import brief from '@/service/korrespondenz'
import ver from '@/service/vereinbarung'
import beleg from '@/service/beleg'
import kal from '@/service/kalender'
import PdfViewer from '../../compo/PdfViewer.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BTable,
    BPagination,
    PdfViewer,
  },
  data() {
    const status = [
      { value: 'ev', label: 'Eingabe in Vorbereitung' },
      { value: 'ee', label: 'Eingabe erfolgt' },
      { value: 'eq', label: 'Eingabe quittiert' },
      { value: 'ze', label: 'Zustellung erfolgt' },
    ]

    return {
      status,
      e: null,
      et: null,
      be: null,
      pdfIn: null,
      dateiName: null,
      begehren: '',
      vereinbarung: '',
      currRow: null,
      currPage: 1,
      fieldsERVTrans: [
        { key: 'DatumTbl', label: 'Datum' }, // eslint-disable-line
        { key: 'ArtBez', label: 'Übermittlung' }, // eslint-disable-line
        { key: 'Bez', label: 'Bezeichnung' }, // eslint-disable-line
        { key: 'FristTbl', label: 'Frist' }, // eslint-disable-line
      ],
      fieldsBelege: [
        { key: 'BelegName', label: 'Beleg' }, // eslint-disable-line
        { key: 'Dateiname', label: 'Datei' }, // eslint-disable-line
        // { key: 'Anzeigen' }, // eslint-disable-line
      ],
    }
  },
  async created() {
    const re = await erv.getERV(this.$root.currDosID)
    if (re !== null && re.data !== null && re.data.length > 0) this.e = re.data[0]
    this.begehren = this.e.Art === 0 ? 'Ehechutzbegehren' : 'Scheidungsbegehren'
    this.vereinbarung = this.e.Art === 0 ? 'Vereinbarung' : 'Konvention'

    const rt = await erv.getERVTrans(this.$root.currDosID)
    if (rt !== null && rt.data !== null && rt.data.length > 0) this.et = rt.data

    // Belege...
    const rb = await beleg.getDossierBelege(this.$root.currDosID, this.$g.user.BenSprache)
    if (rb.data.length > 0) {
      for (let i = 0; i < rb.data.length; i += 1) rb.data[i]._showDetails = false // eslint-disable-line
      this.be = rb.data
    }
  },
  methods: {
    async speichernERV() {
      await erv.putERV(this.e)
    },
    async einreichen() { // Ersteingabe... to be implemented...
      await erv.putERVEingabe(this.et)
    },
    async einsicht() { // Einsichtnahme mit Akten-Rubriken... to be implemented...
      // console.log('Einsichtnahme ab API Akten lesen und URLs anzeigen zur Anzeige, idealerweise mittels PDF-Viewer Komponente..')
    },
    async download(what) { // Pdfs werden anzgezeigt, andere Doks werden heruntergeladen...
      let d = null
      if (what === 'v') d = await dos.getDossierVollmacht(this.$root.currDosID)
      else if (what === 'b') d = await brief.getBriefDatei(this.e.BegehrenID, true)
      else if (what === 'k') d = await ver.getVereinbarungDatei(this.$root.currDosID, false)
      else if (what === 'f') d = await beleg.getBelegDatei(this.$root.currDosID, this.e.FamilienausweisID, 'application/pdf')

      this.dateiName = what === 'v' ? 'Vollmacht.pdf' : ((what === 'b' ? this.begehren : this.vereinbarung) + '.pdf')
      this.pdfIn = d.data // new Blob(d.data, { type: 'application/pdf' })
      this.$refs.pv.show()
    },
    async showERVTrans(items) {
      if (items.length > 0) this.currRow = items[0]
      this.currRow._showDetails = !this.currRow._showDetails  // eslint-disable-line
    },
    async showBeleg(items) {
      if (items.length === 0) return
      const rb = await beleg.getBelegDatei(this.$root.currDosID, items[0].DosBelegID)
      if (rb !== null) {
        if (items[0].Dateiname.endsWith('.pdf')) {
          this.dateiName = items[0].Dateiname
          this.pdfIn = rb.data
          this.$refs.pv.show()
        } else {
          const fileURL = window.URL.createObjectURL(rb.data)
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', what === 'v' ? 'Vollmacht.pdf' : ((what === 'b' ? this.begehren : this.vereinbarung) + '.pdf')) // eslint-disable-line
          document.body.appendChild(fileLink)
          fileLink.click()
        }
      }
    },
    addEingabe() {
      const np = JSON.parse(JSON.stringify(this.et)).slice(0, 1)
      np[0].ERVTransID = -(this.et.length + 1)
      np[0].Datum = new Date()
      np[0].Art = 'e'
      np[0].Bezeichnung = 'Zusatzeingabe...'
      np[0].Frist = null
      np[0].FristTage = null
      np[0].Einsicht = null
      np[0].Dok = null
      np[0].IstErstEingabe = 0
      this.et.push(np[0])
    },
    async createTermin(item) {
      const r = await kal.getKalender(this.$root.currDosID, this.$g.user.BenID)
      if (r.data.length === 0) return
      const ka = r.data[0]
      ka.DosID = this.$root.currDosID
      ka.Id = -ka.Id
      ka.Subject = item.Bezeichnung
      ka.Descripiton = item.Bez
      ka.StartTime = item.Frist
      ka.StartTimezone = ''
      ka.EndTime = item.Frist
      ka.EndTimezone = ''
      ka.Location = ''
      ka.IsAllDay = 0
      ka.IsReadOnly = 0
      ka.IsBlock = 0
      const rk = await kal.putKalender(ka)
      item.KalID = rk.data[0] // eslint-disable-line
      // save ERVTrans with id...
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
