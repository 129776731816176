import axios from '@axios'

export default {
  async getKalender(dosID, benID) {
    try {
      const r = await axios.get('sproc/getKalender', {
        params: {
          dosID,
          benID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putKalender(data) {
    try {
      const r = await axios.get('sproc/getKalenderInsUpd', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async delKalender(id) {
    try {
      const r = await axios.get('sproc/getKalenderDelete', {
        params: {
          id,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
